import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form as FormikForm, Field } from 'formik';
import Button from '../buttons/button';
import fetch from 'isomorphic-unfetch';
import * as Yup from 'yup';
import ReactGA from 'react-ga'
import { loadStripe } from "@stripe/stripe-js"
import Cookies from 'js-cookie';
import courseData from '../../utils/course-data';
import moment from 'moment';

const FormContainer = styled.div`
  h1 {
    text-align: left;
    padding: 0 0 10px 0;
  }
  p {
    font-size: 14px;
    color: var(--black);
    text-align: left;
  }
  .radio-input {
    width: 20px;
    margin-right: 5px;
  }
  .radio-label {
    font-weight: bold;
  }

  &.center {
    h1 {
      text-align: center;
    }
  }
`

const SuccessHeader = styled.h2`
  text-align: center;
`

const ErrorMsg = styled.div`
  color: red;
`

const FieldContainer = styled.div`
  width: 100%;
  font-family: 'roboto-mono';
  margin: 15px 0;
  input {
    width: 100%;
  }
`
const loadingStyles = {
  opacity: '0.7'
}

const fireGTMEvent = () => {
  ReactGA.event({
    category: 'CTA - Button',
    action: 'Click',
    label: 'waitlist-submit',
  })
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
  date: Yup.string()
    .required('Required')
});

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_KEY}`)
  }
  return stripePromise
}
 
export default function WaitListForm ({ close, align, price, location }) {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false)

  const checkOut = async () => {
    const stripe = await getStripe()

    fetch('https://striper-server.herokuapp.com/prep-course', {
      method: 'POST',
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(session) {
        return stripe.redirectToCheckout({ sessionId: session.id });
      })
      .then(function(result) {
        if (result.error) {
          alert(result.error.message);
        }
      })
      .catch(function(error) {
        console.error('Error:', error);
      });
  }


  const submitForm = ({email, firstName, lastName, phone, date, ipAddress}) => {
    const isBrowser = typeof window !== 'undefined';
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const formID = '7a132616-f285-470b-880b-3c54ffa0c801';
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/8389530/${formID}`;

    setLoading(true);

    fireGTMEvent();
		
    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: email,
        },
        {
          name: 'firstname',
          value: firstName
        },
        {
          name: 'lastname',
          value: lastName
        },
        {
          name: 'phone',
          value: phone
        },
        {
          name: 'prep_course_start_date',
          value: date
        }
      ],
      context: {
        hutk,
        pageUri,
        pageName,
        ipAddress
      },
    };

    fetch(postUrl, {
      method: 'post',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
    })
      .then(res => res.json())
      .then(() => {
        checkOut();
      })
      .catch(err => {
        console.log(err);
        setSuccess(false);
      });
  };

  const showSuccess = () => { 
    if (success) {
      return (
        <>
          <SuccessHeader>Thanks!</SuccessHeader>
          <br></br>
          <p>Look out for an email from us in the next 24 hours! Be sure to check your SPAM!</p>
        </>
      )
    } else {
      return (
        <FormContainer className={(align ? align : null)}>
          <h1>Prep Course Enroll</h1>
          <p>After filling out the form, you will be redirected to pay.</p>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              date: ''
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              fetch('https://api.ipify.org/?format=json')
                .then(res => res.json())
                .then((data) => {
                  submitForm(values, data.ip)
                })
                .catch(err => {
                  console.log(err);
                })
            }}
          >
            {({ errors, touched }) => (
              <FormikForm>
                <FieldContainer>
                  <Field
                    placeholder='Email Address'
                    name="email" type="email"
                  />
                  <ErrorMsg>
                    {errors.email && touched.email ? <div>{errors.email}</div> : null}
                  </ErrorMsg>
                </FieldContainer>
              
                <FieldContainer>
                  <Field
                    placeholder='First Name'
                    name='firstName'
                  />
                  <ErrorMsg>
                  {errors.firstName && touched.firstName ? (
                    <div>{errors.firstName}</div>
                  ) : null}
                  </ErrorMsg>
                </FieldContainer>
                
                <FieldContainer>
                  <Field
                    placeholder='Last Name'
                    name="lastName"
                  />
                  <ErrorMsg>
                    {errors.lastName && touched.lastName ? (
                      <div>{errors.lastName}</div>
                    ) : null}
                  </ErrorMsg>
                </FieldContainer>

                <FieldContainer>
                  <Field
                    placeholder='Phone Number'
                    name="phone"
                  />
                  <ErrorMsg>
                    {errors.phone && touched.phone ? (
                      <div>{errors.phone}</div>
                    ) : null}
                  </ErrorMsg>
                </FieldContainer>

                <FieldContainer>
                  <p className='radio-label'>Which start date are you enrolling for?</p>
                  
                  {courseData.upcomingPrepCourses.map(function (course, i) {
                    return (
                      <div key={i}>
                        <label>
                          <Field className='radio-input' type="radio" name='date' value={moment(course.startDate).format('MMMM D, YYYY')} />
                          {moment(course.startDate).format('MMMM D, YYYY')}
                        </label>
                      </div>
                    )
                  })}
                
                  <ErrorMsg>
                    {errors.date ? (
                      <div>{errors.date}</div>
                    ) : null}
                  </ErrorMsg>
                </FieldContainer>
                
                <div className={(align ? `text-${align}` : 'text-left')}>
                  <Button style={loading ? {...loadingStyles} : null}  text={loading ? 'Redirecting...' : 'Enroll'} type='submit' />
                </div>   
              </FormikForm>
            )}
          </Formik>
        </FormContainer>
      )
    }
  };

  return showSuccess();
}